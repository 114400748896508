<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.page_id"
          :items="pageOptions"
          item-text="href"
          item-value="id"
          label="Select Page"
          outlined
          hide-details
          :disabled="isReserved"
        ></v-select>

        <v-select
          v-model="form.type"
          :items="[
            {label: 'Plain', value: 'plain'},
            {label: 'Carousel', value: 'carousel'}
          ]"
          item-text="label"
          item-value="value"
          label="Select Type"
          outlined
          hide-details
          class="mt-5"
          :disabled="isReserved"
        ></v-select>

        <v-text-field
          v-model="form.key"
          label="Key"
          outlined
          :rules="[required]"
          class="mt-5"
          :disabled="isReserved"
        />

        <v-text-field
          v-model="form.sort"
          type="number"
          label="Sort"
          outlined
          :rules="[required]"
          class="mt-5"
        />

        <v-text-field
          v-model="form.notes"
          label="Notes for admin, e.g. use FAQ's title for recognition."
          outlined
          :rules="[required]"
          class="mt-5"
        />

        <v-select
          v-model="form.selection_id"
          :items="selectionOptions"
          item-text="title"
          item-value="value"
          label="Auto Fetch Selection (Can leave empty)"
          clearable
          :disabled="isReserved"
        >
        </v-select>

        <v-select
          v-model="elementModel"
          label="Element"
          outlined
          multiple
          class="mt-5"
          :items="classOptions"
        ></v-select>

        <!-- <v-select
          v-model="form.sync_ids"
          :items="distributorOptions"
          item-text="title"
          item-value="value"
          label="Select Distributor"
          outlined
          chips
          multiple
          hide-details
        >
        </v-select> -->

        <p>Note: leave empty to allow all distributors to see them.</p>
        <!-- <v-card>
          <v-card-title>
            Element Section
          </v-card-title>
          <v-card-text>
            <div v-for="(element, index) in elements" :key="index">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select v-model="element.attrValue" :items="selectItems" label="Select"
                    outlined></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="element.textValue" label="Text" outlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" class="d-flex align-center">
                  <v-btn icon @click="addElement">
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                  <v-btn icon @click="removeElement(index)" v-if="elements.length > 1">
                    <v-icon>{{icons.mdiMinus}}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card> -->

        <v-select
          v-model="form.feature_id"
          :items="featuresOptions"
          item-text="key"
          item-value="id"
          label="Required Feature (empty=all visible)"
          clearable
          class="mt-2"
        ></v-select>

        <div class="d-flex align-center">
          <v-switch v-model="form.auth" />
          Login user visible

          <v-select
            v-if="form.auth"
            v-model="form.auth_state"
            :items="authStateOptions"
            item-text="title"
            item-value="value"
            label="When"
            clearable
            class="ml-2"
          ></v-select>
        </div>

        <div class="d-flex align-center">
          <v-switch v-model="form.guest" />
          Guest visible
        </div>

        <div class="d-flex align-center">
          <v-switch v-model="form.active" />
          Active
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="(msg,index) in errors"
        :key="index"
        :sort="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { getSelections } from '@/api/selection'
import { fetchSitePages } from '@/api/site/site'
import { TranslatableInput, TranslationLocales } from '@/components'
import useFeatureList from '@/modules/feature/views/useFeatureList'
import { required } from '@core/utils/validation'
import { mdiMinus, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useSiteList from '../../site-list/useSiteList'

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    tableList: {
      type: [Object, Array],
      default: null,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const elementModel = ref([])

    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })
    const elementField = (name, initValue) => {
      if (props.resource && props.resource[name].class && props.resource[name].class.length > 0) {
        console.log('class', props.resource[name].class)

        // elementModel.value = props.resource[name].class.split(' ')
        elementModel.value = props.resource[name].class
      }

      return {
        [name]: props.resource ? props.resource[name] : initValue,
      }
    }

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      ...field('page_id', null),
      ...field('active', true),
      ...field('auth', true),
      ...field('auth_state', null),
      ...field('guest', true),
      ...field('type', null),
      ...field('sort', 0),
      ...field('key', null),
      ...field('notes', ''),
      ...field('selection_id', null),
      ...field('feature_id', null),
      ...elementField('element', {
        class: '',
      }),

      // ...field('sync_ids', []),
    }

    // 'page_id' => $this->page->id,
    // 'sort' => 100,
    // 'key' => 'home_hero_carousel',
    // 'element' => [
    //     'class' => ''
    // ]

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const pageOptions = ref([])
    const selectionOptions = ref([])

    const { featuresOptions, loadFeaturesData } = useFeatureList()
    loadFeaturesData({ take: 999 })

    const initOptions = async () => {
      await Promise.all([
        fetchSitePages({ take: 999 }).then(res => {
          pageOptions.value = res.data.data.records
        }),
        getSelections({ take: 999 }).then(res => {
          selectionOptions.value = res.data.data.records.map(d => ({
            title: d.name.en,
            value: d.id,
          }))
        }),
      ])
    }

    initOptions()

    const validate = () => {
      console.log(elementModel.value)
      const combinedElementClassesString = elementModel.value.join(' ')
      console.log(combinedElementClassesString)
      form.value.element = {
        class: combinedElementClassesString,
      }
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const elements = ref([
      {
        attrValue: '',
        textValue: '',
      },
    ])

    const selectItems = ['class']
    const addElement = () => {
      elements.value.push({
        attrValue: '',
        textValue: '',
      })
    }
    const removeElement = index => {
      elements.value.splice(index, 1)
    }

    const classOptions = ref(['right-to-left', 'left-to-right'])

    const { authStateOptions } = useSiteList()

    // const { distributorOptions } = useDistributor()

    return {
      form,
      formElem,
      validate,
      required,

      elements,
      selectItems,
      elementModel,
      addElement,
      removeElement,

      pageOptions,
      selectionOptions,
      featuresOptions,
      classOptions,
      authStateOptions,

      isReserved: props.resource && props.resource.reserved,
      icons: {
        mdiPlus,
        mdiMinus,
      },
    }
  },
}
</script>
@/modules/feature/views/useFeatureList
