var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('v-select', {
    attrs: {
      "items": _vm.pageOptions,
      "item-text": "href",
      "item-value": "id",
      "label": "Select Page",
      "outlined": "",
      "hide-details": "",
      "disabled": _vm.isReserved
    },
    model: {
      value: _vm.form.page_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "page_id", $$v);
      },
      expression: "form.page_id"
    }
  }), _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "items": [{
        label: 'Plain',
        value: 'plain'
      }, {
        label: 'Carousel',
        value: 'carousel'
      }],
      "item-text": "label",
      "item-value": "value",
      "label": "Select Type",
      "outlined": "",
      "hide-details": "",
      "disabled": _vm.isReserved
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Key",
      "outlined": "",
      "rules": [_vm.required],
      "disabled": _vm.isReserved
    },
    model: {
      value: _vm.form.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "type": "number",
      "label": "Sort",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Notes for admin, e.g. use FAQ's title for recognition.",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notes", $$v);
      },
      expression: "form.notes"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.selectionOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Auto Fetch Selection (Can leave empty)",
      "clearable": "",
      "disabled": _vm.isReserved
    },
    model: {
      value: _vm.form.selection_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "selection_id", $$v);
      },
      expression: "form.selection_id"
    }
  }), _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "Element",
      "outlined": "",
      "multiple": "",
      "items": _vm.classOptions
    },
    model: {
      value: _vm.elementModel,
      callback: function callback($$v) {
        _vm.elementModel = $$v;
      },
      expression: "elementModel"
    }
  }), _c('p', [_vm._v("Note: leave empty to allow all distributors to see them.")]), _c('v-select', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.featuresOptions,
      "item-text": "key",
      "item-value": "id",
      "label": "Required Feature (empty=all visible)",
      "clearable": ""
    },
    model: {
      value: _vm.form.feature_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "feature_id", $$v);
      },
      expression: "form.feature_id"
    }
  }), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-switch', {
    model: {
      value: _vm.form.auth,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "auth", $$v);
      },
      expression: "form.auth"
    }
  }), _vm._v(" Login user visible "), _vm.form.auth ? _c('v-select', {
    staticClass: "ml-2",
    attrs: {
      "items": _vm.authStateOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "When",
      "clearable": ""
    },
    model: {
      value: _vm.form.auth_state,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "auth_state", $$v);
      },
      expression: "form.auth_state"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-switch', {
    model: {
      value: _vm.form.guest,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "guest", $$v);
      },
      expression: "form.guest"
    }
  }), _vm._v(" Guest visible ")], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-switch', {
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }), _vm._v(" Active ")], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg, index) {
    return _c('v-alert', {
      key: index,
      attrs: {
        "sort": msg,
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }